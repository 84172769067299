:root {
  --main-color: #9f9f9f;
  --heading-color: #fff;
  --primary-color: #eb5d3a;
  --lighter-color: #ccc;
  --subtitle-color: #ccc;
  --black-color: #000;
  --border-color: rgba(119, 119, 125, .2);
  --button-border: rgba(119, 119, 125, .2);
  --body-background: #000;
  --grey-bg: #E6EAEE;
}


@media (min-width: 992px) {
  .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
  }
}



/* Preloader */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.load-text {
  font-size: 2rem;
  font-weight: bold;
  animation: loading-animation 1.5s infinite;
}

@keyframes loading-animation {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}




.single-resume{
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: 20px;
  box-shadow: #ffffff06 0 .362176px .651917px -1px inset, #ffffff09 0 3px 5.4px -2px inset;
  background-color: #121214;
}


.experience-list {
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: 10px;
}

.resume-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid rgba(119, 119, 125, .2);
  padding-bottom: 30px;
  padding-top: 30px;
}

.resume-item .icon {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-transition: .5s;
  transition: .5s;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  background: #f9f9f9;
  line-height: 50px;
  border-radius: 12px;
  text-align: center;
  color: orange;
  font-size: 20px;
}

.resume-item .content .years {
  line-height: 1;
  display: block;
  margin-bottom: 20px;
}

.resume-item .content h4 {
  margin: 12px 0;
  color: white;
  font-size: 20px;
}

.resume-item .content .company {
  color: #77777d;
  font-size: 16px;
}




.about-image-part {
  text-align: center;
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: 20px;
  box-shadow: #ffffff06 0 .362176px .651917px -1px inset, #ffffff09 0 3px 5.4px -2px inset;
  background-color: #121214;
  height: -webkit-fill-available;
}

.about-image-part img {
  width: 300px;
  height: fit-content;
  display: inline-block;
}

.about-image-part h2 {
  font-size: 40px;
}

.about-social {
  margin-top: 30px;
}

.about-social a {
  font-size: 20px;
  border: 1px solid rgb(255 255 255 / 5%);
  padding: 10px 12px;
  border-radius: 10px;
  color: orange;
}


.about-content-part, .about-content-part-bottom {
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: 20px;
  box-shadow: #ffffff06 0 .362176px .651917px -1px inset, #ffffff09 0 3px 5.4px -2px inset;
  background-color: #121214;
}

.about-content-part h2 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 20px;
  font-weight: 400;
}

.adress-field ul li i {
  color: #0f0;
  margin-right: 10px;
  font-size: 13px;
}

.theme-btn {
  background: var(--primary-color);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  border: 1px solid var(--button-border);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  overflow: visible;
  border-radius: 15px;
  padding: 12px 24px;
  text-align: center;
  text-transform: none;
  -webkit-transition: .3s;
  transition: .3s;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  animation: scroll-left 20s linear infinite;
  white-space: nowrap;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.scroller__inner img {
  margin-right: 30px;
  flex-shrink: 0; /* Prevent images from shrinking */
}


:root{
  --play: running;
  --direction: normal;
  --duration: 17.940244140625s;
  --delay: 0s;
  --iteration-count: infinite;
  --min-width: 100%;
  --pause-on-hover: running;
  --pause-on-click: running;
  --width: 100%;
  --transform: none;
}

.rfm-marquee-container {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  width: var(--width);
  transform: var(--transform);
}

.rfm-marquee {
  flex: 0 0 auto;
  min-width: var(--min-width);
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
  animation-play-state: var(--play);
  animation-delay: var(--delay);
  animation-direction: var(--direction);
}

.rfm-child {
  transform: var(--transform);
}
