@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: var(--main-color);
  background: var(--body-background);
  font-weight: 400;
  line-height: 30px;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
